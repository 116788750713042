<template>
    <div style="padding: 10px;text-align:right;background-color:white;">
      <v-toolbar dense elevation="0" >

        <v-toolbar-title>
            <h1><v-icon large color="black">mdi-gesture-tap-hold</v-icon> {{$t('players.totems', locale)}}</h1>
        </v-toolbar-title>

        <v-spacer />
        <v-btn
          @click="onRefresh"
          style="margin-right: 10px;"
        >
          {{ $t('common.refresh', locale) }}
        </v-btn>
        <v-menu
          top
          close-on-click
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-down</v-icon>
              <v-icon>mdi-view-module</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in viewTypes"
              :key="index"
              @click="onChangeView(item.id)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!--v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn-->
      </v-toolbar>
      <v-layout wrap>
        <v-flex xs3
          v-for="(item, index) of devices"
          :key="`card${index}`"
          style="padding: 10px;"
        >
          <v-card
            class="mx-auto"
            @click="onEdit(item.ID)"
            style="border: 1px solid;"
            :style="{ border: item.Seconds > 120 ? '1px solid red' : null }"
          >
            <v-img
              :src="item.Screenshot"
            ></v-img>

            <v-card-title>
              {{item.Name}}
            </v-card-title>

            <v-card-subtitle>
            <small>&Uacute;lt.Con.:</small> {{convertSeconds(item.Seconds)}} <br/>
            <small>Capturado:</small> {{convertSeconds(item.ScreenshotSeconds)}}
            </v-card-subtitle>

            <!--v-card-actions>
              <v-btn
                color="orange lighten-2"
                text
              >
                Explore
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                icon
                @click="show = !show"
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions-->

            <!--v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                </v-card-text>
              </div>
            </v-expand-transition-->
          </v-card>
        </v-flex>
      </v-layout>
    </div>
</template>
<script>
export default {
  props: {
    viewTypes: {
      type: Array,
      default: null,
    },
    devices: {
      type: Array,
      default: null,
    },
    onChangeView: {
      type: Function,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onRefresh: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  methods: {
    convertSeconds (v) {
      let aux = JSON.parse(JSON.stringify(v))
      let d = parseInt(aux / 86400)
      aux = aux - (d * 86400)
      let h = parseInt(aux / 3600)
      aux = aux - (h * 3600)
      let m = parseInt(aux / 60)
      let s = aux - (m * 60)

      let result = ''
      if (d) result += ` ${d}d`
      if (h) result += ` ${h}h`
      if (m) result += ` ${m}m`
      if (s) result += ` ${s}s`

      return result
    },
  },
}
</script>

